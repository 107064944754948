<template>
  <el-carousel height="360px" class="banner-carousel">
    <el-carousel-item v-for="(item, index) in list" :key="index">
      <img class="img" :src="item.img" />
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
export default {
  data() {
    return {
      list: [
        {
          img: require("@/assets/images/banner1.png"),
        },
        {
          img: require("@/assets/images/banner2.png"),
        },
        {
          img: require("@/assets/images/banner3.png"),
        },
      ],
    };
  },
  mounted() {
    // 如果是演示数据的话
    this.loadAssets();
  },
  methods: {
    async loadAssets() {
      const res = await commonApi.GetDocData("home");
      if (res && res.code === 200) {
        this.list = res.data.bannerList.map((item) => {
          const suffix = item.split(".")[1];
          return {
            img: getAssetsFile(item, suffix),
          };
        });
      }
    },
  },
};
</script>

<style lang="scss">
.banner-carousel {
  .img {
    width: 100%;
  }
  .el-carousel__arrow--right {
    right: 50%;
    margin-right: -636px;
  }
  .el-carousel__arrow--left {
    left: 50%;
    margin-left: -636px;
  }
}
</style>
