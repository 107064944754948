<template>
  <div class="home">
    <plat-banner></plat-banner>
    <p-living></p-living>
    <p-notice></p-notice>
    <p-fine></p-fine>
    <p-public></p-public>
    <p-micro></p-micro>
    <p-total></p-total>
    <div class="fixed">
      <el-popover placement="left" trigger="hover" class="pointer">
        <div class="popover">
          <img class="pic" src="../../assets/images/qrcode.jpg" alt />
          <div class="font">扫码关注微信公众号</div>
        </div>
        <template v-slot:reference>
          <div class="publicicon flex">
            <i class="iconfont icon-phone2"></i>
          </div>
        </template>
      </el-popover>
      <el-backtop :bottom="100" :right="30" :visibility-height="1080">
        <div class="backTop flex">
          <i class="iconfont icon-backTop"></i>
        </div>
      </el-backtop>
    </div>
  </div>
</template>

<script>
import PlatBanner from "./components/Banner";
import PLiving from "./components/Living";
import PNotice from "./components/Notice";
import PFine from "./components/Fine";
import PPublic from "./components/Public";
import PMicro from "./components/Micro";
import PTotal from "./components/Total";
export default {
  components: {
    PlatBanner,
    PLiving,
    PNotice,
    PFine,
    PPublic,
    PMicro,
    PTotal,
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/mixins.scss";
.block {
  padding: 0 0 40px;
  &.gray {
    background-color: #f8f8f8;
  }
  .inner {
    width: 1200px;
    margin: 0 auto;
  }
}
// 左边标题 右边更多
.more-title {
  padding: 40px 0 20px;
  .left {
    vertical-align: baseline;
  }
  .title {
    font-size: 20px;
  }
  .remark {
    margin-left: 10px;
    color: #666;
    // em {
    //   color: $primary-color;
    // }
  }
  .more {
    font-size: 14px;
    color: #999999;
    cursor: pointer;
    &::after {
      margin-left: 5px;
      content: "\e62b";
    }
  }
}

// 头像名字
.avatar-name {
  font-size: 12px;
  color: #666;
  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.course-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  .item {
    cursor: pointer;
    .img {
      width: 100%;
      height: 160px;
    }
    .title {
      margin-top: 10px;
      @include single_text_overflow(284px);
    }
    .desc {
      margin-top: 9px;
      font-size: 12px;
      color: #666666;
    }
  }
}
//右下角图标组
.pointer {
  cursor: pointer;
}
.popover {
  .pic {
    width: 160px;
    height: 160px;
  }
  .font {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    text-align: center;
  }
}
//右下角图标组
.fixed {
  position: fixed;
  // bottom: 270px;
  // right: 200px;
  left: 100%;
  margin-left: -70px;
  top: 100%;
  margin-top: -190px;
  .publicicon {
    width: 40px;
    height: 40px;
    background: #3984fc;
    border-radius: 4px;
    justify-content: center;
    .icon-phone2 {
      font-size: 20px;
      color: #ffffff;
    }
  }
  .backTop {
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    justify-content: center;
    .icon-backTop {
      font-size: 19px;
      color: #999999;
    }
  }
}
</style>
