<template>
  <div class="block">
    <div class="inner">
      <div class="more-title flex-between">
        <div class="left">
          <span class="title">精品课程</span>
          <span class="remark">最新更新 <em>72</em> 节</span>
        </div>
        <span class="more iconfont" @click="$router.push('/rec/list/1')">
          更多
        </span>
      </div>
      <div class="course-box">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="$router.push('/rec/detail/' + item.id)"
          class="item"
        >
          <img class="img" :src="item.imgSrc" />
          <div class="title">{{ item.courseName }}</div>
          <div class="desc flex-between">
            <span class="avatar-name flex">
              <img class="avatar" :src="item.avatar" />
              {{ item.teacher }}
            </span>
            <span>{{ item.views }}人观看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonApi from "@/api/common";
import { getAssetsFile, disturbList } from "@/scripts/utils";
export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      let assetsList = [];
      const res = await commonApi.GetDocData("fine");
      if (res && res.code === 200) {
        assetsList = res.data.fine.map((item) => {
          const imgSuffix = item.imgSrc.split(".")[1];
          const avaSuffix = item.avatar.split(".")[1];
          return {
            ...item,
            imgSrc: getAssetsFile(item.imgSrc, imgSuffix),
            avatar: getAssetsFile(item.avatar, avaSuffix),
          };
        });
        const num = 8 - this.list.length;
        this.list = this.list.concat(disturbList(assetsList.splice(0, num)));
      }
    },
  },
};
</script>
