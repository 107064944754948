<template>
  <div class="block gray">
    <div class="inner">
      <div class="more-title flex-between">
        <div class="left">
          <span class="title">直播预告</span>
          <!-- <span class="remark">最新更新 <em>72</em> 节</span> -->
        </div>
        <span class="more iconfont" @click="$router.push('/live/notice')">
          更多
        </span>
      </div>
      <Card
        @fakeRefresh="fakeRefresh"
        @getData="getData"
        :teachers="list"
        class="Grid"
      ></Card>
    </div>
  </div>
</template>

<script>
import Card from "../../../components/RecCard/index.vue";
import apiLive from "@/api/live";
import commonApi from "@/api/common";
import { getAssetsFile, disturbList } from "@/scripts/utils";
// import fakedata from '../../../models/appointment'

export default {
  components: {
    Card,
  },
  data() {
    return {
      list: [],
      turnnum: 0,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await apiLive.list({
        pageSize: 8,
        pageCurrent: 1,
        state: 0,
        startMin: String(Date.parse(new Date())),
      });
      if (res.code === 200) {
        this.list = res.data.list.map((item) => {
          let ava = "";
          const random = Math.floor(Math.random()) * 10 + 1;
          if (window.Config.isDemostrate) {
            ava = getAssetsFile(`teacher${random}.png`, "png");
          } else {
            ava = item.lecturerPhoto;
          }
          return {
            ...item,
            lecturerPhoto: ava,
          };
        });
        if (this.list.length < 8) {
          this.turnnum = this.list.length;
          this.loadAssets();
        }
      }
    },
    // 添加加数据
    async loadAssets() {
      let assetsList = [];
      const res = await commonApi.GetDocData("living");
      if (res && res.code === 200) {
        assetsList = res.data.living.map((item) => {
          const avaSuffix = item.lecturerPhoto.split(".")[1];
          const imgSuffix = item.cover.split(".")[1];
          return {
            ...item,
            isFake: true,
            cover: getAssetsFile(item.cover, imgSuffix),
            lecturerPhoto: getAssetsFile(item.lecturerPhoto, avaSuffix),
          };
        });
        const num = 8 - this.list.length;
        // for (let i = 0; i < num; i++) {
        //   this.list.push(assetsList[i]);
        // }
        this.list = this.list.concat(disturbList(assetsList.splice(0, num)));
      }
    },
    // Addfake() {
    //   const num = 8 - this.list.length;
    //   for (let index = 0; index < num; index++) {
    //     this.list.push(fakedata[index]);
    //   }
    // },
    fakeRefresh(index, bor) {
      console.log(this.turnnum, "真数据长度");
      console.log(index, bor, "传参");
      this.list[index + this.turnnum].isBook =
        !this.list[index + this.turnnum].isBook;
    },
  },
};
</script>

<style lang="scss" scoped>
.notice-card-wrap {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  column-gap: 20px;
  .card {
    margin-top: 0;
    margin-bottom: 20px;
    border: none;
  }
}
.Grid {
  display: grid;
  grid-template-columns: 280px 280px 280px 280px;
  grid-template-rows: 150px;
  grid-gap: 0px 20px;
}
</style>
