<template>
  <div class="block gray">
    <div class="inner">
      <div class="more-title flex-between">
        <div class="left">
          <span class="title">公开课</span>
          <span class="remark">最新更新 <em>72</em> 节</span>
        </div>
        <span class="more iconfont" @click="$router.push('/rec/list/public')">
          更多
        </span>
      </div>
      <div class="course-lr">
        <div
          v-for="(item, index) in list"
          :key="index"
          @click="$router.push('/rec/detail/' + item.id)"
          class="box item"
        >
          <img class="img" :src="item.imgSrc" />
          <div class="info">
            <div class="title">{{ item.courseName }}</div>
            <div class="desc flex-between">
              <span class="avatar-name flex">
                <img class="avatar" :src="item.avatar" />
                {{ item.teacher }}
              </span>
              <span>{{ item.views }}人观看</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonApi from "@/api/common";
import { getAssetsFile, disturbList } from "@/scripts/utils";
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.loadAssets();
  },
  methods: {
    // 添加加数据
    async loadAssets() {
      let assetsList = [];
      const res = await commonApi.GetDocData("public");
      if (res && res.code === 200) {
        assetsList = res.data.public.map((item) => {
          const suffix = item.imgSrc.split(".")[1];
          const avaSuffix = item.avatar.split(".")[1];
          return {
            ...item,
            imgSrc: getAssetsFile(item.imgSrc, suffix),
            avatar: getAssetsFile(item.avatar, avaSuffix),
          };
        });
        const num = 6 - this.list.length;
        this.list = this.list.concat(disturbList(assetsList.splice(0, num)));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/mixins.scss";
.box {
  border-radius: 4px;
  background-color: #fff;
  box-sizing: border-box;
}
.course-lr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 20px;
  .item {
    display: flex;
    padding: 20px;
    cursor: pointer;
    .img {
      width: 213px;
      height: 120px;
      margin-right: 20px;
      border-radius: 4px;
    }
    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 13px;
      .title {
        line-height: 24px;
        @include text-overflow(3);
      }
      .desc {
        font-size: 12px;
        color: #666;
      }
    }
  }
}
</style>
