<template>
  <div class="block total">
    <div class="inner">
      <div class="more-title flex-between">
        <div class="left">
          <span class="title">网站数据统计</span>
        </div>
      </div>
      <div class="total-list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <em class="num">{{ item.num }}</em>
          <span class="title">{{ item.title }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonApi from "@/api/common";
export default {
  data() {
    return {
      list: [
        {
          num: 6824,
          title: "线上课程资源",
        },
        {
          num: 10245,
          title: "直播次数",
        },
        {
          num: 925,
          title: "专递课堂次数",
        },
        {
          num: 1006,
          title: "优质教研师资",
        },
      ],
    };
  },
  mounted() {
    this.loadAssets();
  },
  methods: {
    // 添加加数据
    async loadAssets() {
      const res = await commonApi.GetDocData("home");
      if (res && res.code === 200) {
        this.list = this.list.map((item, index) => {
          return {
            ...item,
            num: res.data.total[index],
          };
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.total-list {
  height: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  color: #fff;
  .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    &:first-child {
      background-image: url("../../../assets/images/home_wzsjtj_bg1.png");
    }
    &:nth-child(2) {
      background-image: url("../../../assets/images/home_wzsjtj_bg2.png");
    }
    &:nth-child(3) {
      background-image: url("../../../assets/images/home_wzsjtj_bg3.png");
    }
    &:nth-child(4) {
      background-image: url("../../../assets/images/home_wzsjtj_bg4.png");
    }
    .num {
      font-size: 40px;
    }
    .title {
      margin-top: 10px;
    }
  }
}
</style>
