<template>
  <div class="block">
    <div class="inner">
      <div class="more-title flex-between">
        <div class="left">
          <span class="title">正在直播</span>
        </div>
        <span class="more iconfont" @click="$router.push('/live/list')">
          更多
        </span>
      </div>
      <div class="live-main">
        <div
          v-for="(item, index) in list"
          :key="index"
          class="item"
          @click="toDE(item)"
        >
          <d-cover
            :src="item.cover"
            :height="index === 0 ? 365 : 135"
          ></d-cover>
          <div class="title-line flex">
            <span class="bg-orange tag">{{ item.subjectName }}</span>
            <span class="title">{{ item.title }}</span>
          </div>
          <div class="desc">
            <span class="live-gif">正在直播</span>
            <span class="tip">{{ item.numberOfWatch }}人观看</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiLive from "@/api/live";
import commonApi from "@/api/common";
import { getAssetsFile } from "@/scripts/utils";
// import fakedata from "../../../models/appointment";

export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.GetlivingList();
  },
  methods: {
    async GetlivingList() {
      const res = await apiLive.list({
        pageSize: 5,
        pageCurrent: 1,
        state: 1,
      });
      if (res && res.code === 200) {
        this.list = res.data.list.map((item) => {
          return {
            ...item,
            isFake: false,
          };
        });
        if (this.list.length < 5) {
          this.loadAssets();
        }
      }
    },
    //添加假数据
    // Addfake() {
    //   const num = 5 - this.list.length;
    //   for (let index = 0; index < num; index++) {
    //     this.list.push(fakedata[index]);
    //   }
    // },
    // 添加加数据
    async loadAssets() {
      let assetsList = [];
      const res = await commonApi.GetDocData("living");
      if (res && res.code === 200) {
        assetsList = res.data.living.map((item) => {
          const suffix = item.cover.split(".")[1];
          return {
            ...item,
            isFake: true,
            cover: getAssetsFile(item.cover, suffix),
          };
        });
        const num = 5 - this.list.length;
        this.list = this.list.concat(assetsList.splice(0, num));
      }
    },
    toDE(item) {
      window.open(`/live/detail/${item.id}/${item.isFake}`, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../assets/styles/mixins";
.bg-orange {
  background-color: #f09933;
}
.bg-blue {
  background-color: #3399f0;
}
.live-main {
  display: grid;
  grid-template-columns: 660px 240px 240px;
  grid-template-rows: repeat(2, 1fr);
  gap: 40px 30px;
  .item {
    cursor: pointer;
    &:first-child {
      grid-area: 1/1/3/2;
    }
    &:not(:first-child) {
      width: 240px;
    }
    .img {
      width: 100%;
    }
    .title-line {
      margin-top: 10px;
      .tag {
        display: inline-block;
        width: 36px;
        height: 20px;
        line-height: 20px;
        margin-right: 10px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        border-radius: 2px;
      }
      .title {
        @include single_text_overflow(194px);
      }
    }
    .desc {
      margin-top: 10px;
      // .icon-live {
      //   font-size: 12px;
      //   color: $primary-color;
      //   &::before {
      //     margin-right: 6px;
      //   }
      // }
      .tip {
        font-size: 12px;
        margin-left: 20px;
        color: #666666;
      }
    }
  }
}
</style>
